<template>
    <div id="calculator">
        <!-- Form -->
        <section id="form">
            <div class="section-content section-content-xs style-basic">
                <div class="container">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 text-center">
                            <p class="h1 styled-header font-weight-bold">Калькулятор</p>
                            <p class="h4 color-basic-text font-weight-normal mb-5">Расчет стоимости покупки покрытия</p>
                        </div>
                    </div>

                    <!-- Form -->
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <form @submit.stop.prevent="submit()" method="GET" action="/buy">
                                <!-- Amount -->
                                <label class="h5">Площадь стен (м<sup>2</sup>)</label>
                                <input name="meters" type="number" min="1" ref="meters" placeholder="Площадь стен, на которые хотите нанести штукатурку" required class="form-control form-control-lg border-md style-basic mb-3">

                                <!-- Material -->
                                <label class="h5">Покрытие/Материал</label>
                                <select name="id" ref="materialId" @change="onMaterialChange" class="form-control custom-select form-control-lg border-md style-basic mb-4">
                                    <option v-for="product in products" :key="product.id" :value="product.id">{{product.nameru}}</option>
                                </select>

                                <div ref="additionalOptions"></div>

                                <!-- Calculate -->
                                <div class="d-flex flex-column">
                                    <button type="submit" class="btn btn-md style-active default$ w-100 font-title m-auto">Рассчитать цену</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
var currentMaterialOptions = [];
export default {
    name: 'Calculator',
    data: function() {
        return {
            products: window.data.products
        }
    },
    methods: {
        mounted: function() {
            window.scrollTo(0,0);
        },
        submit: function() {
            let additionalParams = '';

            //options
            if(currentMaterialOptions.length > 0) {
                let optionName = document.querySelector('[name="option"]:checked')?.value
                if(optionName && optionName != 'none')
                    additionalParams += `&opt=${optionName}`
            }

            this.$router.push(`/buy?id=${this.$refs.materialId.value}&meters=${this.$refs.meters.value}${additionalParams}`);
        },

        onMaterialChange: function(e) {
            const currentMaterial = this.products.find(product => product.id == e.target.value);
            
            currentMaterialOptions = [];

            //material has options
            if(currentMaterial.options) {
                let optionsHtml = '';

                optionsHtml += `
                    <div class="form-check form-control-lg mb-2">
                        <input class="form-check-input" type="radio" checked="true" name="option" value="none" id="noneOption">
                        <label class="form-check-label" for="noneOption">
                            Без доп. материала
                        </label>
                    </div>
                    `;
                
                currentMaterial.options.forEach(optionName => {
                    var option = window.data.options.filter((option) => option.name == optionName)[0];
                    
                    optionsHtml += `
                    <div class="form-check form-control-lg mb-2">
                        <input class="form-check-input" type="radio" name="option" value="${optionName}" id="${optionName}">
                        <label class="form-check-label" for="${optionName}">
                            ${option.nameru} <span>(~ ${option.estimatedPrice} &#8381;/м<sup>2</sup>)</span>
                        </label>
                    </div>
                    `;

                    currentMaterialOptions.push(option.name)
                })

                this.$refs.additionalOptions.innerHTML = optionsHtml;
            }
            else 
                this.$refs.additionalOptions.innerHTML = '';
        },
    }
}
</script>